export const useMediaStore = defineStore("media", () => {
  const { $toast } = useNuxtApp();

  async function uploadFile({
    file,
    folder,
    category,
  }: {
    file: File;
    folder: string;
    category: string;
  }) {
    const formData = new FormData();
    const fileName = file.name.endsWith(".jpg")
      ? file.name.slice(0, -4) + ".jpeg"
      : file.name;
    formData.append("file", file);
    formData.append("name", fileName);
    formData.append("folder", folder);
    formData.append("category", category);
    try {
      const res = await $fetch("/api/file/upload", {
        method: "POST",
        body: formData,
      });
      const extension = fileName.split(".").pop()?.toLowerCase() || "";
      const mimeType = file.type || "application/octet-stream";
      const fileSize = file.size;
      return {
        ...res,
        extension,
        mime_type: mimeType,
        file_size: fileSize,
      };
    } catch (error) {
      $toast.error(
        "Failed to upload file. Please try again later or contact support.",
      );
      throw error;
    }
  }

  async function uploadMedia({
    file,
    agentId,
    workflowId,
  }: {
    file: File;
    agentId?: string;
    workflowId?: string;
  }) {
    const formData = new FormData();
    const fileName = file.name.endsWith(".jpg")
      ? file.name.slice(0, -4) + ".jpeg"
      : file.name;
    formData.append("file", file);
    formData.append("name", fileName);
    formData.append("agentId", agentId);
    formData.append("workflowId", workflowId);

    try {
      const res = await $fetch("/api/media/upload", {
        method: "POST",
        body: formData,
      });
      return {
        ...res,
        file,
        upload_status: "success" as UploadStatus,
      };
    } catch (error) {
      $toast.error(error.data?.message || "Failed to upload media. Please try again later or contact support.");
      throw error;
    }
  }

  async function uploadFileToMetaServer({
    file,
    workflowId,
  }: {
    file: File;
    workflowId: string;
  }) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("workflowId", workflowId);

    try {
      const res = await $fetch("/api/meta/upload-whatsapp-media", {
        method: "POST",
        body: formData,
      });

      return res;
    } catch (error) {
      $toast.error(error.data?.message || "Failed to upload media. Please try again later or contact support.");
      throw error;
    }
  }

  return {
    uploadFile,
    uploadMedia,
    uploadFileToMetaServer
  };
});

export type UploadStatus = "idle" | "uploading" | "success" | "error";

export type Media = {
  name: string;
  path: string;
  file_size: number;
  mime_type: string;
  extension: string;
  width: number;
  height: number;
  file?: File;
  url?: string;
  upload_status?: UploadStatus;
  error_message?: string;
};
